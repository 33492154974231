<template>
  <div v-myTitle :data-title="textTitle">
    <div class="cobweb">
      <div class="myfile" v-if="this.$store.state.userInfo.type == 1">我的报告</div>
      <div class="myfile" v-else>{{this.$store.state.staffInfo.name}}的报告</div>
      <div
        id="leiDaTu"
        class="echart"
        style="width: 100%;height: 320px;margin:0 auto"
      ></div>
      <div class="intro">
        <div class="introTitle">报告说明：</div>
        <div class="introContent">• 距离中心点越远，表示发展需求指数越高，即目前技能水准较弱；</div>
        <div class="introContent">• 距离中心点越近，表示发展需求指数越低，即目前技能水准较强。</div>
      </div>
      <div v-if="this.$store.state.userInfo.type == 2">
<!--        <button class="recorbtn" @click="goCobweb(2)" v-if="type == 1">综合报告</button>-->
<!--        <button class="recorbtn" @click="goCobweb(1)" v-if="type == 2">个人报告</button>-->
<!--        <button class="recorbtn" @click="goScore" v-if="userInfo.type == 1">评分记录</button>-->
        <button class="recorbtn" @click="goStaff">返回员工列表</button>
      </div>

    </div>
  </div>
</template>

<script>
import { getCob } from '@/request/api'

export default {
  name: "Cobweb",
  data() {
    return {
      textTitle: this.$title,
      indicator: [],
      series: [],
      type: 2,
      status: 0,
      userInfo: {},
      title: []
    }
  },
  methods: {
    drawPie() {
      let charts = this.$echarts.init(document.getElementById("leiDaTu", null));
      var option = {
        title: this.title,
        legend: [{
          show: true,
          data: ['员工自评', '主管评分', '综合评分'],
          icon: 'rect',
          left: 'center',
          top: 10
        }],
        radar: {
          name: {
            textStyle: {
              color: '#fff',
              backgroundColor: '#666',
              borderRadius: 3,
              padding: [3, 3],
              fontSize: 10
            }
          },
          center: ['50%', '50%'],
          radius: 70,
          startAngle: 130,
          splitNumber: 6,
          indicator: this.indicator,
        },
        series: this.series,
      };
      console.log(option, 'option');
      charts.setOption(option, true);
      charts.resize();
    },
    goScore() {
      this.$router.push('/matrix')
    },
    goCobweb(type) {
      if(type == 2 && this.status == 1) {
        this.$dialog({"message": "【报告正在生成中，待经理评阅后可查】"}).then(()=>{this.$router.replace('/login')})
      }
      this.type = type
      this.getCobweb()
    },
    getCobweb() {
      let staff_id = 0
      if (this.userInfo.type == 2) {
        staff_id = this.$store.state.staffInfo.id
      }
      getCob({
        staff_id: staff_id,
        type: this.type
      }).then((res) => {
        this.indicator = []
        this.series = []
        this.status = 0
        this.indicator = res.data.indicator
        this.series = res.data.series
        this.status = res.data.status
        if(this.status == 2 && this.$store.state.userInfo.type == 1) {
          this.type = 2
        }
        if (this.status == 1 && this.$store.state.userInfo.type == 1) {
          this.series = []
        }
        if (this.type == 2) {
          this.title = [{
            text: '综合报告',
            left: 10,
            top: 10,
            textStyle: {
              fontSize: 14
            }
          }]
        } else {
          this.title = [{
            text: '个人报告',
            left: 10,
            top: 10,
            textStyle: {
              fontSize: 14
            }
          }]
        }
        this.$nextTick(function() {
          this.drawPie("leiDaTu");
          if (this.status == 1 && this.$store.state.userInfo.type == 1){
            this.$dialog.alert({
              "message": "【报告正在生成中，待经理评阅后可查】"
            }).then(()=>{
              this.$router.replace("/")
            });
          }
        });
      });
    },
    goStaff() {
      this.$router.replace('/scores')
    }
  },
  created() {
    this.userInfo = this.$store.state.userInfo
    console.log(this.userInfo)
  },
  mounted() {
    this.getCobweb();
  },
};
</script>

<style>
.myfile {
  width: 80%;
  margin: 0 auto;
  height: 30px;
}
.echart {
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
}
.recorbtn{
  width: 60%;
  height: 40px;
  margin: 30px auto;
  display: block;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0 0 5px 3px rgb(37, 161, 221);
  background-image: linear-gradient(#00B3F1, #00A3EF);
}
.intro {
  width: 90%;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 20px;
  text-align: left;
}
.introContent {
  margin-top: 5px;
  padding-left: 0;
}
</style>
